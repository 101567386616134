const images = import.meta.glob([
  "assets/img/main/main_spb-s.jpg",
  "assets/img/main/main_spb-s.webp",
  "assets/img/main/main_moscow-s.jpg",
  "assets/img/main/main_moscow-s.webp",
  "assets/img/main/main_kazan-s.jpg",
  "assets/img/main/main_kazan-s.webp",
  "assets/img/main/popular-kaliningrad-s.jpg",
  "assets/img/main/popular-kaliningrad-s.webp",
  "assets/img/main/sochi-s.jpg",
  "assets/img/main/sochi-s.webp",
  // "assets/img/main/popular-gelendzhik.jpg",
  // "assets/img/main/popular-gelendzhik.webp",
  "assets/img/main/popular-sheregesh-s.jpg",
  "assets/img/main/popular-sheregesh-s.webp",
  // "assets/img/main/popular-sirius.jpg",
  // "assets/img/main/popular-sirius.webp",
  "assets/img/main/popular-krasnaya-polyana-s.jpg",
  "assets/img/main/popular-krasnaya-polyana-s.webp",
  // "assets/img/main/anapa.jpg",
  // "assets/img/main/anapa.webp",
  "assets/img/main/arhyz-s.jpg",
  "assets/img/main/arhyz-s.webp",
  "assets/img/main/popular-minsk-s.jpg",
  "assets/img/main/popular-minsk-s.webp",
  "assets/img/main/popular-vladivostok-s.jpg",
  "assets/img/main/popular-vladivostok-s.webp",
  // "assets/img/main/popular-mahachkala.jpg",
  // "assets/img/main/popular-mahachkala.webp",
  "assets/img/main/popular-nn-s.jpg",
  "assets/img/main/popular-nn-s.webp",
  "assets/img/main/ekaterinburg-s.jpg",
  "assets/img/main/ekaterinburg-s.webp",
  "assets/img/main/kislovodsk-s.jpg",
  "assets/img/main/kislovodsk-s.webp",
  "assets/img/main/krasnodar-s.jpg",
  "assets/img/main/krasnodar-s.webp",
], { eager: true, query: "?url", import: "default" });

const citiesCards = [
  {
    title: "spb",
    count: 24168,
    link: "//spb.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_spb-s.jpg"],
    imgWebp: images["/assets/img/main/main_spb-s.webp"],
  },
  {
    title: "moscow",
    count: 15844,
    link: "//www.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_moscow-s.jpg"],
    imgWebp: images["/assets/img/main/main_moscow-s.webp"],
  },
  {
    title: "kazan",
    count: 7815,
    link: "//kazan.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_kazan-s.jpg"],
    imgWebp: images["/assets/img/main/main_kazan-s.webp"],
  },
  {
    title: "kaliningrad",
    count: 6553,
    link: "//kaliningrad.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-kaliningrad-s.jpg"],
    imgWebp: images["/assets/img/main/popular-kaliningrad-s.webp"],
  },
  {
    title: "sochi",
    count: 25328,
    link: "//sochi.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/sochi-s.jpg"],
    imgWebp: images["/assets/img/main/sochi-s.webp"],
  },
  // {
  //   title: "gelendzhik",
  //   count: 6020,
  //   link: "//gelendzhik.sutochno.ru?from=mainpage",
  //   img: images["/assets/img/main/popular-gelendzhik.jpg"],
  //   imgWebp: images["/assets/img/main/popular-gelendzhik.webp"],
  // },
  {
    title: "sheregesh",
    count: 1137,
    link: "//sheregesh.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-sheregesh-s.jpg"],
    imgWebp: images["/assets/img/main/popular-sheregesh-s.webp"],
  },
  // {
  //   title: "sirius",
  //   count: 4198,
  //   link: "//adler.sutochno.ru/sirius?from=mainpage",
  //   img: images["/assets/img/main/popular-sirius.jpg"],
  //   imgWebp: images["/assets/img/main/popular-sirius.webp"],
  // },
  {
    title: "krasnayaPolyana",
    count: 1820,
    link: "//krasnaya-polyana.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-krasnaya-polyana-s.jpg"],
    imgWebp: images["/assets/img/main/popular-krasnaya-polyana-s.webp"],
  },
  // {
  //   title: "anapa",
  //   count: 8961,
  //   link: "//anapa.sutochno.ru/?from=mainpage",
  //   img: images["/assets/img/main/anapa.jpg"],
  //   imgWebp: images["/assets/img/main/anapa.webp"],
  // },
  {
    title: "arhyz",
    count: 1437,
    link: "//cherkessk.sutochno.ru/arhyz?from=mainpage",
    img: images["/assets/img/main/arhyz-s.jpg"],
    imgWebp: images["/assets/img/main/arhyz-s.webp"],
  },
  {
    title: "minsk",
    count: 2808,
    link: "//minsk.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-minsk-s.jpg"],
    imgWebp: images["/assets/img/main/popular-minsk-s.webp"],
  },
  {
    title: "vladivostok",
    count: 3141,
    link: "//vl.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-vladivostok-s.jpg"],
    imgWebp: images["/assets/img/main/popular-vladivostok-s.webp"],
  },
  // {
  //   title: "mahachkala",
  //   count: 9946,
  //   link: "//mahachkala.sutochno.ru/region?from=mainpage",
  //   img: images["/assets/img/main/popular-mahachkala.jpg"],
  //   imgWebp: images["/assets/img/main/popular-mahachkala.webp"],
  // },
  {
    title: "nn",
    count: 3798,
    link: "//nn.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-nn-s.jpg"],
    imgWebp: images["/assets/img/main/popular-nn-s.webp"],
  },
  {
    title: "ekaterinburg",
    count: 4095,
    link: "//ekaterinburg.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/ekaterinburg-s.jpg"],
    imgWebp: images["/assets/img/main/ekaterinburg-s.webp"],
  },
  {
    title: "kislovodsk",
    count: 3405,
    link: "//kislovodsk.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/kislovodsk-s.jpg"],
    imgWebp: images["/assets/img/main/kislovodsk-s.webp"],
  },
  {
    title: "krasnodar",
    count: 5538,
    link: "//krasnodar.sutochno.ru/?from=mainpage",
    img: images["/assets/img/main/krasnodar-s.jpg"],
    imgWebp: images["/assets/img/main/krasnodar-s.webp"],
  },
];

export default citiesCards;