<i18n lang="json">
{
  "ru": {
    "rightTitle": "В приложении \n больше!",
    "heading": "Получайте кэшбэк бонусами \n до 30% от общей стоимости \n проживания.",
    "description": "Максимальный кэшбэк в мобильном \n приложении Суточно.ру!",
    "moreBtn": "Узнать о кэшбэке",
    "download": "Установить приложение"
  },
  "en": {
    "rightTitle": "More in the app!",
    "heading": "Get cashback up to 30% \n of the total cost of your stay.",
    "description": "Maximum cashback \n in the mobile application \n Sutochno.ru!",
    "moreBtn": "Learn about cashback",
    "download": "Download application"
  }
}
</i18n>

<template>
  <div class="get-cashback">
    <MountainsBanner
      :labels="MOUNTAINS_LABELS_DESKTOP"
      class="get-cashback__left"
    />
    <!-- 
    <BlackFridayBanner
      :labels="BLACK_FRIDAY_LABELS_DESKTOP"
      class="get-cashback__left"
    /> -->

    <div class="get-cashback__right">
      <div class="get-cashback__group">
        <p class="get-cashback__right-title heading-h3">
          {{ t("rightTitle") }}
        </p>
        <p class="get-cashback__heading heading-h7">{{ t("heading") }}</p>
        <p class="get-cashback__description regular-p6">
          {{ t("description") }}
        </p>
      </div>
      <span @click="showCashbackModal" class="get-cashback__more semibold-p8">
        {{ t("moreBtn") }}
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.41602 11.8108L10.416 8.81079L7.41602 5.81079"
            stroke="white"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <!-- Поставить актуальную ссылку и подставить класс для кнопки из UI Kit (сейчас стили в scoped) -->
      <UIButton buttonTheme="red" @click="openApp">
        {{ t("download") }}
      </UIButton>
    </div>

    <AppInfo v-model="viewAppInfo" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { event as sendEventGtag } from "@/composables/gtag";
import { MOUNTAINS_LABELS_DESKTOP } from "@/components/base/MainBanners/constants";

import UIButton from "@/components/UIKit/UIButton.vue";
import MountainsBanner from "@/components/base/MainBanners/MountainsBanner.vue";
import AppInfo from "./AppInfo.vue";

const { t } = useI18n();
const store = useStore();

const showCashbackModal = () => {
  sendEventGtag("modal_cashback", {
    click: "about_cashback",
  });
  store.commit("user/setShowCashbackModal", true);
};

const viewAppInfo = ref(false);
function openApp() {
  viewAppInfo.value = true;
}
</script>

<style lang="scss" scoped>
.get-cashback {
  margin-top: 43px;
  display: flex;
  gap: 16px;

  &__modal {
    .modal {
      &__header {
        border-radius: 20px 0px 0px 0px;
        height: 165px;
        background-image: url("@/assets/img/main/cashback-modal-header-bg.png");
        background-repeat: no-repeat;
        background-color: #e7e9f3;
        background-position-x: 100%;
        background-position-y: 95%;
        background-size: 75%;
        padding: 39px 41px;

        .header {
          &__heading {
            color: var(--color-main-black);
            margin-bottom: 9px;
          }
          &__text {
            color: var(--color-gray-g5);
            margin: 0;
          }
        }
      }

      &__content {
        padding: 10px 30px 0px 30px;
        overflow: auto;
        .content {
          &__heading {
            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
            color: #1c1c1c;
          }
          &__list-heading {
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            display: flex;
            align-items: center;
            color: #1c1c1c;
            margin-top: 26px;
            margin-bottom: 17px;
          }
          &__list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 0;
            padding: 0;
          }
          &__list-item {
            display: flex;
            align-items: center;
            gap: 15px;
            .list-item {
              &__step {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #5a5d63;
                background-color: #f1f3fb;
                border-radius: 50%;
              }
              &__link {
                color: var(--color-accent);
              }
              &__text {
                flex-basis: 427px;
                margin: 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: #5a5d63;
              }
            }
          }

          &__dropdown {
            margin: 0;
            padding: 0;
            .dropdown {
              &__item {
                display: grid;
                grid-template-columns: 22px 1fr;
                border-top: 1px solid #f1f3fb;
                padding: 15px 0;
                cursor: pointer;
              }
              &__item-icon {
                color: #1c1c1c;
                font-weight: 900;
                font-size: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                transition: all 0.3s;
              }
              &__item-heading {
                font-weight: 600;
                font-size: 18px;
                color: #1c1c1c;
                margin: 0;
              }
              &__item-text {
              }
            }
          }
        }
      }

      &__footer {
        display: flex;
        padding: 30px 30px 10px 30px;
        border-top: 1px solid #f1f3fb;
        justify-content: space-between;
        .footer {
          &__button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 15px 48px;
            border: none;
            outline: none;
            border-radius: 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            &_secondary {
              background: #f1f3fb;
              color: #1c1c1c;
            }
            &_primary {
              background: #f51449;
              color: #ffffff;
              width: 100%;
            }
          }
          &__button-icon {
            font-size: 16px;
            margin-right: 11px;
          }
        }
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__heading {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__description {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__left {
    width: 50%;
  }

  &__right-title {
    white-space: pre-line;
    color: var(--color-white);
    margin: 0;
  }

  &__right {
    width: 50%;
    height: 423px;
    background-image: url("@/assets/img/main/main-right.jpg");
    background-color: var(--color-gray-g3);
    background-repeat: no-repeat;
    border-radius: 30px;
    padding: 34px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center center;
    background-size: cover;

    button {
      margin-top: 24px;
      max-width: 260px;
    }
  }

  &__more {
    margin-top: 33px;
    color: var(--color-white);
    display: inline-flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal__content::-webkit-scrollbar {
  width: 8px;
}

.modal__content::-webkit-scrollbar-track {
  background: white;
}

.modal__content::-webkit-scrollbar-thumb {
  background-color: #5a5d63;
  border-radius: 20px;
}

.dropdown__item-icon::before {
  transition: all 0.3s;
  transform: rotateX(180deg);
}

.rotate-icon::before {
  transform: rotateX(0);
}
</style>
