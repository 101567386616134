<i18n lang="json">
    {
      "ru": {
        "title": "Куда поехать",
        "options": "{n} вариантов | {n} вариант | {n} варианта | {n} вариантов",
        "cities": {
          "spb": "Санкт-Петербург",
          "moscow": "Москва",
          "kazan": "Казань",
          "kaliningrad": "Калининград",
          "sochi": "Сочи",
          "sheregesh": "Шерегеш",
          "gelendzhik": "Геленджик",
          "krasnayaPolyana": "Красная Поляна",
          "sirius": "Сириус",
          "arhyz": "Архыз",
          "anapa": "Анапа",
          "minsk": "Минск",
          "vladivostok": "Владивосток",
          "nn": "Нижний Новгород",
          "mahachkala": "Дагестан",
          "ekaterinburg": "Екатеринбург",
          "kislovodsk": "Кисловодск",
          "krasnodar": "Краснодар"
        }
      },
      "en": {
        "title": "Where to go",
        "options": "{n} options | {n} option | {n} options | {n} options",
        "cities": {
          "spb": "Saint Petersburg",
          "moscow": "Moscow",
          "kazan": "Kazan",
          "kaliningrad": "Kaliningrad",
          "sochi": "Sochi",
          "sheregesh": "Sheregesh",
          "gelendzhik": "Gelendzhik",
          "krasnayaPolyana": "Krasnaya Polyana",
          "sirius": "Sirius",
          "arhyz": "Arhyz",
          "anapa": "Anapa",
          "minsk": "Minsk",
          "vladivostok": "Vladivostok",
          "nn": "Nizhny Novgorod",
          "mahachkala": "Dagestan",
          "ekaterinburg": "Ekaterinburg",
          "kislovodsk": "Kislovodsk",
          "krasnodar": "Krasnodar"
        }
      }
    }
</i18n>

<template>
  <div class="where-go">
    <h2 class="where-go__title heading-h6">{{ t("title") }}</h2>

    <div class="where-go__group">
      <a
        :href="card.link"

        v-for="(card, i) in citiesCards"
        :key="i"
      >
        <div class="where-go__card">
        <span class="where-go__count card-label semibold-p7">
          {{ numberFormat(t("options", card.count)) }}
        </span>
        <picture>
          <source
            v-if="card.imgWebp"
            :srcset="card.imgWebp"
            type="image/webp"
          />
          <img
            :src="card.img"
            loading="lazy"
            :alt="t(`cities.${card.title}`)"
          />
        </picture>
        </div>
        <span
            class="where-go__card-title medium-p5"
        >
          {{ t(`cities.${card.title}`) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import numberFormat from "@/utils/numberFormat";
import citiesCards from "@/composables/static/whereToGo";

const { t } = useI18n();

</script>

<style lang="scss" scoped>
.where-go {
  margin-top: 50px;

  &__title {
    text-align: center;
  }

  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 30px;
  }

  &__card {
    display: inline-block;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(100% - 20px);

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(48, 48, 48, 0) 53.12%,
        rgba(31, 31, 31, 0.46) 100%
      );
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      transition: 0.3s;
      object-fit: cover;
    }

    &--small {
      width: calc(16.6666% - 13.4px);

      .where-go__count {
        top: 16px;
        left: 16px;
      }

      .where-go__card-title {
        left: 16px;
        bottom: 14px;
      }
    }
  }

  &__card-title {
    color: var(--color-main-black);
    display: block;
    width: 100%;
    text-align: center;
    // margin-top: 9px;
  }

  &__count {
    left: 8px;
    top: 8px;
  }
}
</style>
