<i18n lang="json">
{
  "ru": {
    "title": "Зависай в горах!",
    "withJacuzzi": "Квартиры с джакузи",
    "withPool": "Жильё с бассейном",
    "mountainView": "С видом на горы",
    "forParty": "Коттеджи для вечеринки",
    "withBreakfast": "Отели с завтраком",
    "recreationCenters": "Базы отдыха Архыза"
  },
  "en": {
    "title": "Hang out in the mountains!",
    "withJacuzzi": "Apartments with Jacuzzi",
    "withPool": "Housing with a pool",
    "mountainView": "Mountain view",
    "forParty": "Party Cottages",
    "withBreakfast": "Hotels with breakfast",
    "recreationCenters": "Arkhyz recreation centers"
  }
}
</i18n>

<template>
  <section class="mountains-banner">
    <h3
      class="heading-h3 mountains-banner__title"
      :class="{
        'heading-h3': !isMobile,
        'heading-h6': isMobile,
      }"
    >
      {{ t("title") }}
    </h3>

    <picture>
      <source
        srcset="
          @/assets/img/banner/mountains-banner-mobile.webp,
          @/assets/img/banner/mountains-banner-mobile@2x.webp 2x
        "
        type="image/webp"
        media="(max-width: 576px)"
      />

      <source
        srcset="
          @/assets/img/banner/mountains-banner-mobile.jpg,
          @/assets/img/banner/mountains-banner-mobile@2x.jpg 2x
        "
        type="image/jpeg"
        media="(max-width: 576px)"
      />

      <source
        srcset="
          @/assets/img/banner/mountains-banner.webp,
          @/assets/img/banner/mountains-banner@2x.webp 2x
        "
        type="image/webp"
      />

      <img
        src="@/assets/img/banner/mountains-banner.jpg"
        srcset="@/assets/img/banner/mountains-banner@2x.jpg 2x"
        class="mountains-banner__image"
        :alt="t('title')"
        width="572"
        height="423"
      />
    </picture>

    <ul class="mountains-banner__labels">
      <template v-for="label in labels">
        <li
          v-if="label.link && label.textCode"
          :key="label.textCode"
          class="mountains__label"
        >
          <a
            :href="label.link"
            target="_blank"
            class="medium-p6 mountains-banner__link"
          >
            {{ t(`${label.textCode}`) }}
          </a>
        </li>
      </template>
    </ul>
  </section>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed } from "vue";

const props = defineProps({
  labels: { type: Array },
});

const { t } = useI18n();
const store = useStore();
const isMobile = computed(() => store.getters["user/isMobile"]);
</script>

<style lang="scss" scoped>
.mountains-banner {
  position: relative;
  height: 423px;
  padding: 40px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 37.14%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #d9d9d9;

  &__title {
    color: var(--color-white);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
    margin: 0;
    z-index: 1;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__labels {
    list-style: none;
    margin: 0;
    margin-top: 36px;
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    padding: 0;
  }

  &__link {
    display: inline-block;
    color: var(--color-white);
    padding: 7px 14px;
    background: var(--color-bg-label-dark);
    backdrop-filter: blur(20px);
    border-radius: 20px;

    &:hover {
      background: var(--color-bg-label-hover);
    }
  }

  @media screen and (max-width: 576px) {
    height: 270px;
    border-radius: 16px;
    background-size: 116%;
    background-position: 85% 16%;
    padding: 16px;

    &__labels {
      margin-top: 16px;
      gap: 6px;
    }

    &__link {
      padding: 5px 10px;
      text-align: center;
    }
  }
}
</style>
