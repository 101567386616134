const images = import.meta.glob([
  // "assets/img/main/routeDirections/ancient-cities.jpg",
  // "assets/img/main/routeDirections/ancient-cities.webp",
  // "assets/img/main/routeDirections/istanbul-holidays.jpg",
  // "assets/img/main/routeDirections/istanbul-holidays.webp",
  // "assets/img/main/routeDirections/palaces-castles.jpg",
  // "assets/img/main/routeDirections/palaces-castles.webp",
  // "assets/img/main/routeDirections/what-to-see-spb.jpg",
  // "assets/img/main/routeDirections/what-to-see-spb.webp",
  "assets/img/main/routeDirections/ski-resorts.jpg",
  "assets/img/main/routeDirections/ski-resorts.webp",
  "assets/img/main/routeDirections/na-more-zimoy.jpg",
  "assets/img/main/routeDirections/na-more-zimoy.webp",
  "assets/img/main/routeDirections/bazy-otdykha.jpg",
  "assets/img/main/routeDirections/bazy-otdykha.webp",
  "assets/img/main/routeDirections/rezidencii-deda-moroza.jpg",
  "assets/img/main/routeDirections/rezidencii-deda-moroza.webp"
], { eager: true, query: "?url", import: "default" });

const directionCards = [
  {
    // Горнолыжные курорты России
    link: "https://sutochno.ru/info/gornolyzhnye-kurorty-russia",
    label: "ideaLabel4",
    description: "ideaDescription4",
    img: images["/assets/img/main/routeDirections/ski-resorts.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/ski-resorts.webp"],
  },
  {
    // На море зимой
    link: "https://sutochno.ru/info/gde-otdokhnut-na-more-zimoy",
    label: "ideaLabel3",
    description: "ideaDescription3",
    img: images["/assets/img/main/routeDirections/na-more-zimoy.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/na-more-zimoy.webp"],
  },
  {
    // Базы отдыха на Новый год
    link: "https://sutochno.ru/info/oteli-i-bazy-otdykha-na-noviy-god",
    label: "ideaLabel",
    description: "ideaDescription",
    img: images["/assets/img/main/routeDirections/bazy-otdykha.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/bazy-otdykha.webp"],
  },
  {
    // Резиденции Деда Мороза
    link: "https://sutochno.ru/info/rezidencii-deda-moroza-v-rossii",
    label: "ideaLabel2",
    description: "ideaDescription2",
    img: images["/assets/img/main/routeDirections/rezidencii-deda-moroza.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/rezidencii-deda-moroza.webp"],
  },
  {
    link: "https://t.me/sutochnoru",
    label: "ideaLabel5",
    description: "ideaDescription5",
  },
];

export default directionCards;