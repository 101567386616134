
export default () => {
  useHead({
    script: [
      {
        type: "application/ld+json",
        children: {
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://sutochno.ru/",
          name: "Суточно.ру",
          potentialAction: {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate: "https://sutochno.ru/front/searchapp/search?term={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        }
      }
    ]
  });
}