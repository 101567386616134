<i18n lang="json">
{
  "ru": {
    "rightTitle": "В приложении \nбольше!",
    "heading": "Получайте кэшбэк бонусами \n до 30% от общей стоимости \n проживания.",
    "description": "Максимальный кэшбэк \n в мобильном приложении \n Суточно.ру!",
    "moreBtn": "Узнать о кэшбэке",
    "download": "Установить приложение"
  },
  "en": {
    "rightTitle": "More in the app!",
    "heading": "Get cashback up to 30% \n of the total cost of your stay.",
    "description": "Maximum cashback \n in the mobile application \n Sutochno.ru!",
    "moreBtn": "Learn about cashback",
    "download": "Install app"
  }
}
</i18n>

<template>
  <div class="get-cashback">
    <!-- <BlackFridayBanner
      :labels="BLACK_FRIDAY_LABELS_MOBILE"
      class="get-cashback__left"
    /> -->
    <div class="get-cashback__right">
      <div class="get-cashback__group">
        <p class="get-cashback__right-title heading-h5">
          {{ t("rightTitle") }}
        </p>
        <p class="get-cashback__heading semibold-p8">
          {{ t("heading") }}
        </p>
        <p class="get-cashback__description regular-p6">
          {{ t("description") }}
        </p>
        <!-- Поставить актуальную ссылку и подставить класс для кнопки из UI Kit (сейчас стили в scoped) -->
        <span @click="showCashbackModal" class="get-cashback__more semibold-p8">
          {{ t("moreBtn") }}
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.41602 11.8108L10.416 8.81079L7.41602 5.81079"
              stroke="white"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <UIButton buttonTheme="red" @click="openApp">
        {{ t("download") }}
      </UIButton>
    </div>
    <MountainsBanner
      :labels="MOUNTAINS_LABELS_MOBILE"
      class="get-cashback__left"
    />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { MOUNTAINS_LABELS_MOBILE } from "@/components/base/MainBanners/constants";

import UIButton from "@/components/UIKit/UIButton.vue";
import MountainsBanner from "~/components/base/MainBanners/MountainsBanner.vue";

const { t } = useI18n();

const store = useStore();

const showCashbackModal = () => {
  store.commit("user/setShowCashbackModal", true);
};

function openApp() {
  window.open(
    "https://redirect.appmetrica.yandex.com/serve/388964938577662096",
    "_blank"
  );
}
</script>

<style lang="scss" scoped>
.get-cashback {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 50px;

  &__left-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-white);
    margin: 0;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 270px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__description {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__heading {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__left {
    width: 100%;
  }

  &__right-title {
    padding-right: 30px;
    margin: 0;
    color: var(--color-white);
    white-space: pre-line;
  }

  &__right {
    width: 100%;
    background-image: url("@/assets/img/main/main-right-mob.jpg");
    background-color: var(--color-gray-g3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 16px;
    padding: 28px 22px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      margin-top: 22px;
      font-size: 14px;
      padding: 11px 24px;
    }
  }

  &__more {
    background: transparent;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: max-content;
    border: none;
    padding: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal__content::-webkit-scrollbar {
  width: 8px;
}

.modal__content::-webkit-scrollbar-track {
  background: white;
}

.modal__content::-webkit-scrollbar-thumb {
  background-color: #5a5d63;
  border-radius: 20px;
}

.dropdown__item-icon::before {
  transition: all 0.3s;
  transform: rotateX(180deg);
}

.rotate-icon::before {
  transform: rotateX(0);
}
</style>
