<i18n lang="json">
    {
      "ru": {
        "title": "Сдавайте жильё на Суточно.ру",
        "subtitle": "Добавляйте свои объекты бесплатно и зарабатывайте вместе с нами!",
        "btn": "С чего начать"
      },
      "en": {
        "title": "Rent out your property on Sutochno.ru",
        "subtitle": "Add your objects for free and earn with us!",
        "btn": "Where to begin"
      }
    }
</i18n>

<template>
  <div class="begin">
    <h2 class="begin__title heading-h4">{{ t("title") }}</h2>
    <p class="begin__subtitle regular-p3">{{ t("subtitle") }}</p>
    <a
      href="https://xn--80aafg2a7bf6i.xn--p1ai/?from=mainbanner"
      class="begin__link medium-p4"
      target="_blank"
    >
      {{ t("btn") }}
    </a>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.begin {
  margin-top: 90px;
  padding: 50px 50px 53px 50px;
  background-color: var(--color-gray-g3);
  border-radius: 30px;
  background-image: url("@/assets/img/main/begin-image.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: 85px;

  &__subtitle {
    color: var(--color-gray-g5);
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__link {
    background: var(--color-accent);
    color: var(--color-white);
    padding: 14px 24px;
    display: inline-block;
    border-radius: 14px;
    margin-top: 20px;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), var(--color-accent);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), #F51449;
    }
  }
}
</style>
