// TODO: Поменять все ссылки на актуальные
export const MOUNTAINS_LABELS_DESKTOP = [
  {
    textCode: "withJacuzzi",
    link: "/front/searchapp/search?is_apartment=1&jacuzzi=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426",
  },
  {
    textCode: "withPool",
    link: "/front/searchapp/search?pool=1&from=mainpage&guests_adults=2&id=287363&type=city&term=%D0%93%D0%B5%D0%BB%D0%B5%D0%BD%D0%B4%D0%B6%D0%B8%D0%BA&SW.lat=44.533968&SW.lng=37.968957&NE.lat=44.614525&NE.lng=38.134193)",
  },
  {
    textCode: "recreationCenters",
    link: "/front/searchapp/search?guests_adults=2&term=%D0%90%D1%80%D1%85%D1%8B%D0%B7&price_per=2&id=410738&SW.lat=43.49218116435179&SW.lng=41.20930253124995&NE.lat=43.600936997651985&NE.lng=41.34113846874997&type=city&is_gl_rec=1&from=mainpage",
  },
  {
    textCode: "forParty",
    link: "/front/searchapp/search?is_house=1&party=1&from=mainpage&guests_adults=2&id=1&term=%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F+%D0%9C%D0%BE%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C&SW.lat=54.255677&SW.lng=35.148867&NE.lat=56.958395&NE.lng=40.204864)",
  },
  {
    textCode: "withBreakfast",
    link: "/front/searchapp/search?is_hotel=1&has_breakfast=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426)",
  },
  {
    textCode: "mountainView",
    link: "/front/searchapp/search?view_mountains=1&from=mainpage&guests_adults=2&id=410738&type=city&term=%D0%90%D1%80%D1%85%D1%8B%D0%B7&SW.lat=43.509017&SW.lng=41.169035&NE.lat=43.584127&NE.lng=41.381406 )",
  },
];

// TODO: Поменять все ссылки на актуальные
export const MOUNTAINS_LABELS_MOBILE = [
  {
    textCode: "withJacuzzi",
    link: "/front/searchapp/search?is_apartment=1&jacuzzi=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426",
  },
  {
    textCode: "mountainView",
    link: "/front/searchapp/search?view_mountains=1&from=mainpage&guests_adults=2&id=410738&type=city&term=%D0%90%D1%80%D1%85%D1%8B%D0%B7&SW.lat=43.509017&SW.lng=41.169035&NE.lat=43.584127&NE.lng=41.381406 )",
  },
  {
    textCode: "withPool",
    link: "/front/searchapp/search?pool=1&from=mainpage&guests_adults=2&id=287363&type=city&term=%D0%93%D0%B5%D0%BB%D0%B5%D0%BD%D0%B4%D0%B6%D0%B8%D0%BA&SW.lat=44.533968&SW.lng=37.968957&NE.lat=44.614525&NE.lng=38.134193)",
  },
  {
    textCode: "withBreakfast",
    link: "/front/searchapp/search?is_hotel=1&has_breakfast=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426)",
  },
];

const BLACK_FRIDAY_MAIN_LABEL_PART = "/front/searchapp/search?from=mainpage&guests_adults=2&price_per=1&hasDiscount=1"

export const BLACK_FRIDAY_LABELS_MOBILE = [
  {
    textCode: "spb",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=397367&type=city&SW.lat=59.63034218114382&SW.lng=29.836607263671794&NE.lat=60.203330519631905&NE.lng=30.77319173632807&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3`,
  },
  {
    textCode: "msk",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=397366&type=city&SW.lat=55.4148162486054&SW.lng=37.14677929412517&NE.lat=56.05866468825663&NE.lng=38.08336376678142&term=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0`,
  },
  {
    textCode: "sochi",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=287390&type=city&SW.lat=43.25823859466232&SW.lng=39.282989263671865&NE.lat=44.08656179266749&NE.lng=40.21957373632811&term=%D0%A1%D0%BE%D1%87%D0%B8`,
  },
  {
    textCode: "minsk",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=398612&type=city&SW.lat=53.819112674596724&SW.lng=27.491716076618683&NE.lat=53.98354371886186&NE.lng=27.603982617146034&term=%D0%9C%D0%B8%D0%BD%D1%81%D0%BA`,
  },
  {
    textCode: "kzn",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=281471&type=city&SW.lat=55.44429140169762&SW.lng=48.63168976367183&NE.lat=56.087655473426864&NE.lng=49.56827423632805&term=%D0%9A%D0%B0%D0%B7%D0%B0%D0%BD%D1%8C`,
  },
  {
    textCode: "nn",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=337931&type=city&SW.lat=56.13482926689829&SW.lng=43.81447358715632&NE.lat=56.444528159218336&NE.lng=44.039006668211&term=%D0%9D%D0%B8%D0%B6%D0%BD%D0%B8%D0%B9+%D0%9D%D0%BE%D0%B2%D0%B3%D0%BE%D1%80%D0%BE%D0%B4`,
  }
];

export const BLACK_FRIDAY_LABELS_DESKTOP = [
  ...BLACK_FRIDAY_LABELS_MOBILE,
  {
    textCode: "kaliningrad",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=312416&type=city&SW.lat=54.623828575383016&SW.lng=20.41766722973629&NE.lat=54.78506827919523&NE.lng=20.52993377026364&term=%D0%9A%D0%B0%D0%BB%D0%B8%D0%BD%D0%B8%D0%BD%D0%B3%D1%80%D0%B0%D0%B4`,
  },
  {
    textCode: "krasnodar",
    link: `${BLACK_FRIDAY_MAIN_LABEL_PART}&id=287323&type=city&SW.lat=44.96737113062712&SW.lng=38.9295462297363&NE.lat=45.164688066795016&NE.lng=39.04181277026366&term=%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80`,
  },
];
