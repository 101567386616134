<i18n lang="json">
  {
    "ru": {
      "routDirectionTitle": "Идеи для отпуска",

      "ideaLabel": "Новый год",
      "ideaLabel2": "С детьми",
      "ideaLabel3": "Куда поехать",
      "ideaLabel4": "Экстрим",
      "ideaLabel5": "Telegram",

      "ideaDescription": "Базы отдыха на Новый год",
      "ideaDescription2": "Резиденции Деда Мороза ",
      "ideaDescription3": "На море зимой",
      "ideaDescription4": "Горнолыжные курорты России",
      "ideaDescription5": "Больше интересного в Телеграм-канале"
    },
    "en": {
      "routDirectionTitle": "Vacation Ideas",

      "ideaLabel": "New Year",
      "ideaLabel2": "With children",
      "ideaLabel3": "Where to go",
      "ideaLabel4": "Extreme",
      "ideaLabel5": "Telegram",

      "ideaDescription": "Recreation centers for the New Year",
      "ideaDescription2": "Residences of Santa Claus",
      "ideaDescription3": "At sea in winter",
      "ideaDescription4": "Ski resorts in Russia",
      "ideaDescription5": "More interesting things in the Telegram channel"
    }
  }
</i18n>

<template>
  <div class="route-direction">
    <p class="route-direction__title heading-h3">{{ t("routDirectionTitle") }}</p>
    <div class="route-direction__container">
      <div
        class="route-direction__card"
        v-for="(card, i) in directionCards"
        :class="{ 'route-direction__card--empty': !card?.img }"
        :key="i"
      >
        <a :href="card.link" :target="card?.img ? '_self' : '_blank'">
          <span
            class="route-direction__card-label card-label"
            :class="{
              'card-label--blue': !card?.img,
              [!card?.img ? 'medium-p6' : 'semibold-p6']: true,
            }"
          >
            {{ t(`${card.label}`) }}
          </span>

          <picture v-if="card?.img">
            <source
              v-if="card.imgWebp"
              :srcset="card.imgWebp"
              type="image/webp"
            />
            <img
              :src="card.img"
              loading="lazy"
              :alt="t(`${card.description}`)"
            />
          </picture>

          <span class="route-direction__card-title heading-h6" v-html="t(`${card.description}`)"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import directionCards from "@/composables/static/routeDirection";

const { t } = useI18n();

</script>

<style lang="scss" scoped>
.route-direction {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 70px;

  &__title {
    text-align: center;
    margin: 0;
  }

  &__container {
    display: flex;
    gap: 16px;
  }

  &__card {
    position: relative;
    min-height: 263px;
    min-width: 219px;
    border-radius: 18px;
    overflow: hidden;

    a {
      position: relative;
      height: 100%;
      display: block;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 48.28%,
          rgba(0, 0, 0, 0.3) 100%
        );
        z-index: 1;
      }
    }

    &--empty {
      padding-right: 16px;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
        0px 5px 10px rgba(20, 45, 136, 0.02);

      a {
        background: var(--color-white);
        border-radius: 18px;
        z-index: 2;

        &:before {
          content: none;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 91px;
        height: 100%;
        border-radius: 18px;
        position: absolute;
        top: 0;
      }

      &:before {
        background: #edf0f9;
        right: 8px;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
          0px 5px 10px rgba(20, 45, 136, 0.02);
        z-index: 1;
      }

      &:after {
        background: #e2e6f0;
        right: 0;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
          0px 4px 15px rgba(20, 45, 136, 0.06);
        z-index: 0;
      }

      .route-direction__card-title {
        color: var(--color-main-black);
        text-shadow: none;
        font-size: 17px;
        padding-right: 25px;
      }
    }
  }

  &__card-title {
    position: absolute;
    bottom: 18px;
    left: 0;
    padding: 0 18px;
    color: var(--color-white);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}
</style>
